<script>
import CLogo from '../components/CLogo.vue'
import CTitleSmall from '../components/CTitleSmall.vue'
import CMessage from '../components/CMessage.vue'

export default {
  name: 'CHomeClosed',

  components: {
    CLogo,
    CTitleSmall,
    CMessage
  },

  computed: {
    today: () => new Date(),
    publishAt () {
      return this.$store.state.publishedInfo.publishAt
    },
    unpublishAt () {
      return this.$store.state.publishedInfo.unpublishAt
    },
    readablePublishAt () {
      return this.$store.state.publishedInfo.readablePublishAt
    },
    readableUnpublishAt () {
      return this.$store.state.publishedInfo.readableUnpublishAt
    },
    isPausedByDate () {
      return this.$store.state.publishedInfo.isPausedByDate
    },
    pause_intro_text () {
      return this.$store.state.textsInfo.pauseText
    }
  }
}
</script>
<template>
  <div>
    <c-logo />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="pause_intro_text"
      v-html="pause_intro_text"
    />
    <!--eslint-enable-->
    <div v-else>
      <c-title-small>
        Frappant Atelierstipendium
      </c-title-small>
      <p class="mt-8 mb-8">
        <strong>
          des Frappant e.V., des Susanne und Michael Liebelt Stiftungsfonds und der Hamburgischen Kulturstiftung
          für Absolvent*innen der Hochschule für bildende Künste Hamburg und der Hochschule für Angewandte Wissenschaften Hamburg im Bereich Bildende Kunst
        </strong>
      </p>
      <p class="mt-8 mb-8">
        Bitte lesen Sie vor dem Ausfüllen des Antragsformulars unbedingt die
        <a
          href="https://frappant.org/home/artikel/news/frappant-atelierstipendium-20232024/"
          target="_blank"
        >Ausschreibung</a>
        und das Merkblatt
        <a
          href="https://frappant.org/home/artikel/news/faq-frappant-atelierstipendium/"
          target="_blank"
        >»Häufig gestellte Fragen/FAQ« </a>
        zum Frappant Atelierstipendium.
        <br>
        Bitte halten Sie für die Online-Bewerbung folgende Unterlagen bereit:
        <ul>
          <li>Motivationsschreiben (unformatierter Text, max. 3.500 Zeichen inkl. Leerzeichen), aus dem hervorgeht:</li>
          <ul>
            <li>Warum bewerben Sie sich für das Atelierstipendium?</li>
            <li>Welches künstlerische Vorhaben möchten Sie während des Atelierstipendiums entwickeln, vertiefen oder verwirklichen?</li>
          </ul>
          <li>Künstlerische Vita (mit Angaben zum Studium/Abschluss und – falls vorhanden – Website, Ausstellungen, Preise, Auszeichnungen oder Stipendien; PDF, max. 2 MB). Das PDF bitte folgendermaßen benennen: Nachname_Vorname_Vita</li>
          <li>Portfolio (PDF, max. 15 Seiten, max. 5 MB). Das PDF bitte folgendermaßen benennen: Nachname_Vorname_Portfolio</li>
        </ul>
        Bitte beachten Sie folgende Hinweise: Sie können Ihren Antrag nicht zwischenspeichern. Die Anträge werden in der Form bearbeitet, wie sie eingehen. Eine nachträgliche Korrektur oder Ergänzung ist nicht möglich.
      </p>
      <br>
      <p>
        Das Antragsformular wird jeweils ca. sechs Wochen vor der Antragsfrist freigeschaltet. In der
        Zwischenzeit ist das Antragsformular in einer Testansicht verfügbar und das Einreichen von
        Anträgen nicht möglich.
      </p>
    </div>
    <br>

    <c-message
      type="error"
      class="errors mb-8"
    >
      <p class="mb-3">
        Das Formular kann aktuell nicht ausgefüllt und abgeschickt werden.
        <template v-if="isPausedByDate ">
          <span> Dies </span>
          <span v-if="publishAt > today">ist </span>
          <span v-else-if="unpublishAt < today">war </span>
          im Zeitraum {{ readablePublishAt }} Uhr bis {{ readableUnpublishAt }} Uhr möglich.
        </template>
      </p>
      <router-link
        :to="{ name: 'request' }"
        class="link"
      >
        <span>Sie können sich das Formular ansehen</span>
      </router-link>
      <span class="mb-3">, jedoch nicht absenden.</span>
    </c-message>
  </div>
</template>

<style lang="scss" scoped>
@use "../css/variables.scss";

.link {
  color: variables.$color-red-100;
}
</style>
