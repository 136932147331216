<template>
  <div class="logo">
    <img
      src="../assets/frappant-logo.jpg"
      alt="Logo Frappant"
      width="90"
      height="100"
    >
    <img
      src="../assets/liebelt-sw.jpg"
      alt="Logo Liebelt SW"
      width="235"
      height="350"
    >
    <img
      src="../assets/HK_Lockup_RGB_Schwarz.svg"
      alt="Logo HKS"
      width="240"
      height="350"
    >
  </div>
</template>

<script>
export default {
  name: 'CLogo'
}
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 3em;

  margin-bottom: 3em;
}
</style>
